<template>
  <div class="mission-info">
    <div class="info">
      <div class="title">内容信息</div>
      <div class="cnt">
        <el-form :model="form">
          <el-form-item label='宣教类型' >{{form.workTypeName}}</el-form-item>
          <el-form-item label='宣教名称' >{{form.workName}}</el-form-item>
          <el-form-item label='发布日期' >{{form.createTime}}</el-form-item>
          <el-form-item label='发布人数' >{{number}}</el-form-item>
          <!-- <el-form-item label='方案开始时间' >{{form.executionTime}}</el-form-item> -->
          <!-- <el-form-item label='方案结束时间' >{{form.endTime}}</el-form-item> -->
          <el-form-item label='具体内容' >
           <div v-if='form.type==3' v-html="form.workContent"></div>
            <template v-else>
              <div id="abc" class="video-js" style='width:80%;height:80%;margin-top:50px'></div>
            </template>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="title">
        患者信息
      </div>
        <el-table :data='followUps' border>
          <!-- <el-table-column type='index'></el-table-column> -->
          <el-table-column  label="姓名" prop='followUpUserName'></el-table-column>
          <el-table-column  label="状态" >
            <template slot-scope="scope">
              <div v-if='scope.row.isRead===1'>已读</div>
              <div v-else-if='scope.row.isRead===2'>未读</div>
              <!-- <div v-else-if='scope.row.executionStatus===3'>已结束</div> -->
            </template>
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name:'feedBackMissionInfo',
  data(){
    return {
      number:'',
      dataListLoading:false,
      form:{},
      followUps:[]
    }
  },
  mounted() {
    let query = this.$route.query
      this.number = query.num
      console.log(this.query,'query');
    if(query.id){
      this.dataListLoading = true
       this.init(query.id)
    }
  },
  methods:{
    async init(id){
      const {data:res} = await this.$httpAes({
        url:this.$httpAes.adornUrl('/missionaryWork/getMissionaryWork'),
        method:'post',
        params:{
          stringParam1:id
        }
      })
      console.log(res,'res info1111111111111111111111111111111111111111');
      if(res.status){
        this.form = res.data
        this.followUps = res.data.followUps
        if(res.data.type!=3){
          this.form.mvUrl = res.data.workContentUrl
          this.imageUrl ='https://aibiboat.oss-cn-shanghai.aliyuncs.com/'+ res.data.workContentUrl+'?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto'
          setTimeout((()=>{
        this.initVideo()
        }),0)
        }
      }else{
        this.$message.error('获取失败，请重试')
      }
    },
    getParams(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    initVideo() {
      let that = this;
      let width = this.getParams("width");
      let height = this.getParams("height");
      // console.log(width);
      var player = new TcPlayer("abc", {
        m3u8:'https://aibiboat.oss-cn-shanghai.aliyuncs.com/'+this.form.workContent, //请替换成实际可用的播放地址
        autoplay: false, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        "poster" : {"style":"stretch", "src": that.imageUrl},
        width:  width || "100%",
        height: height || "100%",
        //playbackRates: [0.5,1,1.2,1.5,2], // 设置变速播放倍率选项，仅 HTML5 播放模式有效
        // controls:'system', // default 显示默认控件，none 不显示控件，system 移动端显示系统控件 备注：如果需要在移动端使用系统全屏，就需要设置为system。默认全屏方案是使用 Fullscreen API + 伪全屏的方式例子
        pausePosterEnabled: true,
        volume: 0.5,
        systemFullscreen: true,
        x5_orientation: 0,
      });
      document.getElementsByClassName("vcp-error-tips")[0].style.fontSize =
        "14px"; // 错误码提示语字体大小，因引入了rem，默认字体大小为100px.8
    },
  }
}
</script>
<style lang="scss" scoped>
.mission-info{
  display: flex;
  justify-content: space-between;
  .title{
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    background-color: #e4e4e4;
    text-indent: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

  }
  .info{
    flex-basis: 70%;
    // height: 700px;
    min-height: 700px;
    height: auto;
    box-shadow:0 0 8px 0 rgba(232,237,250,.6),0 2px 4px 0 rgba(232,237,250,.5);
    border-radius: 5px;
    .cnt{
      padding: 20px;
    }
  }
  .list{
    flex-basis: 28%;
    height: auto;
    min-height: 700px;
    box-shadow:0 0 8px 0 rgba(232,237,250,.6),0 2px 4px 0 rgba(232,237,250,.5);
    border-radius: 5px;

  }
}
</style>